<template>
  <div v-if="showPage" id="club-reconnect">
    <div
      :class="'page-title-area ' + (pageType === 'start-a-club' ? 'start-a-club' : (pageType === 'reconnect' ? 'reconnect' : ''))"
      data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000"
    >
      <div class="container">
        <div class="page-title-content">
          <h2>
            {{pageType === "start-a-club" ? "Start a Club" : (pageType === "reconnect" ? "Reconnect" : "")}}
          </h2>
          <ul>
            <li class="active">Bring PeaceJam to your school or community group</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="loading" style="height: 700px;" v-if="static_pages_data === undefined && !static_pages_error">
      <Loading />
    </div>
    <div v-if="static_pages_data">
      <!-- Start About Our Vision -->
      <section id="our-vision" class="about-section ptb-100" data-aos="fade-up" data-aos-delay="300"
               data-aos-duration="1000">
        <div class="container">
          <div class="row flex-column-reverse flex-lg-row">
            <div class="col-lg-6">
              <div class="about-area-content">
                <h3>{{static_pages_data.data[0].title}}</h3>
              </div>

              <div class="about-tab">
                <div class="white-space-pre-wrap" v-html="static_pages_data.data[0].text"></div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="about-image">
                <DefaultImage :id="static_pages_data.data[0].image" :height="'700px'"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
  import api from "../services/api";
  import useSWRV from "swrv";
  import Loading from "../components/Loading";
  import DefaultImage from "../components/DefaultImage";

  export default {
    name: "ClubPage",
    components: {DefaultImage, Loading},
    data() {
      return {
        pageType: "",
        showPage: true
      }
    },
    created() {
      window.scrollTo(0, 0);
      this.pageType = this.$route.params.slug
    },
    setup(props, { root }) {
      if (!root.$route.params.slug) {
        root.$router.push({ name: '/' });
      }

      const {
        data: static_pages_data,
        error: static_pages_error,
        isValidating: static_pages_isValidating
      } = useSWRV(() => `items/static_pages?status=published&filter[page_type]=${root.$route.params.slug === 'start-a-club' ? 1 : 2}`, api.fetcher);

      return {
        static_pages_data,
        static_pages_error,
        static_pages_isValidating
      };
    },
    watch: {
      '$route.params': {
        handler() {
          window.scrollTo(0, 0);
          this.pageType = this.$route.params.slug;
          this.showPage = false;
          setTimeout(() => {
            this.showPage = true
          }, 0)
        }
      }
    }
  }
</script>

<style lang="scss">
  #club-reconnect {
    .page-title-area {
      background-position: 0 12%;
      background-size: cover;
    }
    .white-space {
      white-space: pre-wrap;
      line-height: 0;

      ul, li, ol {
        line-height: 1.2;
      }

      a {
        color: #31bfd5;
      }

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
        line-height: 1.2;
      }
    }
  }

  .start-a-club {
    background-image: url("../assets/img/SE_youth_from_Parkland.jpg");
  }
  .reconnect {
    background-image: url("../assets/img/films-page-bg.jpeg");
    padding-top: 90px;
    padding-bottom: 75px;
    position: relative;

    >div {
      position: relative;
      z-index: 2;
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00000057;
    }
  }

  @media (max-width: 768px) {
    .about-image > div {
      height: auto !important;
    }
  }
</style>
